import React from "react";
import parse from "html-react-parser";
import { useLocalization } from "gatsby-theme-i18n";
import { Box, Chip, Stack, Typography } from "@mui/material";
import { Trans } from "@lingui/macro";
import _ from "lodash";
import NewLayout from "../components/NewLayout";
import blog from "../cms/blog/blog";
import Container from "../components/Container";
import { transformArticleDate } from "../utils/screen";
import InterestedItems from "../components/InterestedItems";
import { headers } from "../utils/navbar";

function Article({ pageContext }) {
  const bodyRef = React.createRef();
  const { locale, config } = useLocalization();
  const article = _.get(blog[locale]?.[pageContext.slug], "attributes", {});
  const {
    title,
    description,
    content,
    publish_date,
    article_categories,
    publishedAt,
    video_link,
  } = article;
  const hrefLang = config.find((conf) => conf.code === locale)?.hrefLang;
  const isDraft = !publishedAt;
  const interestedItems = [
    { ..._.get(headers(), "media") },
    { ..._.get(headers(), "investors.elements.financial_information_reports.elements.bank_reports") },
    { ..._.get(headers(), "investors.elements.contact") },
  ];

  return (
    <NewLayout
      byDefault
      showOptions
      apps={[{ label: <Trans>News</Trans>, path: "/blog", metadata: { title, description } }]}
      downloadRef={{ ref: bodyRef, title: `Article - ${title}` }}
    >
      {!_.isEmpty(article) && (
        <>
          <Box ref={bodyRef} component="article">
            <Container>
              <Stack spacing={1} mt={3}>
                <Typography variant="h2">{title}</Typography>
                {isDraft && (
                  <Typography color="secondary" fontSize={20}>
                    <Trans>
                      This article is a draft and will not be seen in
                      production.
                    </Trans>
                  </Typography>
                )}
                <Typography>
                  {transformArticleDate(publish_date, hrefLang)}
                </Typography>
                <Box display="flex">
                  {article_categories.data.map(({ attributes }, index) => (
                    <Chip
                      label={attributes.name}
                      variant="filled"
                      sx={{
                        color: (theme) => theme.palette.primary.main,
                        backgrundColor: "rgba(27, 54, 93, 0.2)",
                        ml: index > 0 && 1,
                      }}
                    />
                  ))}
                </Box>
              </Stack>
              <Box fontSize={20} sx={{ "> * a": { wordBreak: "break-word" } }}>
                {parse(content)}
              </Box>
              {video_link && (
                <iframe
                  src={video_link}
                  allowFullScreen
                  title={`video_article_${title}`}
                  allow="autoplay; fullscreen; picture-in-picture"
                  style={{ width: "100%", height: "500px", border: "none" }}
                />
              )}
            </Container>
          </Box>
          <Container pb={8}>
            <InterestedItems items={interestedItems} />
          </Container>
        </>
      )}
    </NewLayout>
  );
}

export default Article;
